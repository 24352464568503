<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :scrollable="options.scrollable"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title>Set special user rights</v-card-title>
      <v-divider />
      <v-card-text class="px-3">
        <div
          v-for="(perm, key) of permissions"
          :key="key"
          class="perm-section"
        >
          <div class="text-uppercase font-weight-bold perm-section-title">
            <v-icon>mdi-{{ perm.icon }}</v-icon>
            {{ perm.id }}
          </div>

          <v-chip-group
            v-model="selectedPermissions"
            column
            multiple
            class="perm-section-content"
          >
            <v-chip
              v-for="(action, actKey) of perm.actions"
              :key="`${perm.id}.${actKey}`"
              filter
              outlined
              :value="`${perm.id}.${action}`"
              :class="getPermissionClasses(`${perm.id}.${action}`)"
            >
              {{ $helper.capFirstLetter(action) }}
            </v-chip>
            <div
              v-if="perm.children"
              class="ml-5"
            >
              <div
                v-for="(child, childKey) of perm.children"
                :key="childKey"
              >
                <div class="mt-2 mb-2 text-uppercase">
                  <v-icon>mdi-{{ child.icon }}</v-icon>
                  {{ $t(`users.permissions.tree.${child.name}`) }}
                </div>
                <v-chip
                  v-for="(subAction, subActKey) of child.actions"
                  :key="`${perm.id}.${subActKey}`"
                  filter
                  outlined
                  :value="`${child.id}.${subAction}`"
                  :class="getPermissionClasses(`${child.id}.${subAction}`)"
                >
                  {{ $helper.capFirstLetter(subAction) }}
                </v-chip>
              </div>
            </div>
          </v-chip-group>
        </div>
        <!-- code>{{ getSelectedPermissions }}</code-->
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-menu
          top
          :offset-x="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              text
              v-bind="attrs"
              :loading="loadTemplate"
              v-on="on"
            >
              {{ $t('Template') }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in roles"
              :key="index"
              link
              @click="setPermissionsByRole(item)"
            >
              <v-list-item-title>{{ $helper.capFirstLetter(item) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          color="red darken-1"
          text
          @click="selectedPermissions = []"
        >
          {{ $t('Clear') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="cancel"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UserPermissions',
  components: {},

  data () {
    return {
      dialog: false,
      options: {
        color: '',
        width: 500,
        zIndex: 200,
        noConfirm: false,
        scrollable: true
      },
      roles: [],
      permissions: [],
      userRolePermissions: [],
      selectedPermissions: [],
      loadTemplate: false,
      user: {}
    }
  },
  computed: {
    getSelectedPermissions() {
      return this.selectedPermissions
    }
  },
  async mounted () {
    await this.$store.dispatch('usersPermissions/getRolesList')
    this.roles = this.$store.getters['usersPermissions/rolesList']

    // if (this.$can('users.permissions.view')) {
    //   await this.$store.dispatch('usersPermissions/getPermissionsList')
    // }
  },
  methods: {
    async open (user, options) {
      this.user = user
      this.dialog = true
      this.options = Object.assign(this.options, options)

      if (this.$can('users.permissions.view')) {
        await this.$store.dispatch('usersPermissions/getPermissionsList', { role: this.user.role })
        this.userRolePermissions = await this.$store.dispatch('usersPermissions/getPermissionsForRole', this.user.role)

        const permissionsObjects = this.$store.getters['usersPermissions/permissionsList']
        this.permissions = permissionsObjects.permissions
      }

      this.selectedPermissions = this.user.permissions || []
      if (this.selectedPermissions.length < 1) {
        this.selectedPermissions = this.userRolePermissions
      }
    },
    async setPermissionsByRole(role) {
      this.loadTemplate = true
      try {
        const permissionsForRole = await this.$store.dispatch('usersPermissions/getPermissionsForRole', role)
        this.selectedPermissions = permissionsForRole
      } catch (e) {
        console.log(e)
      } finally {
        this.loadTemplate = false
      }
    },
    async save(e) {
      try {
        await this.$store.dispatch('users/saveUserPermissions', {
          userId: this.user.id,
          permissions: this.selectedPermissions
        })
        await this.$toast.success('Save completed successfully')
        this.cancel()
      } catch (e) {
        await this.$toast.error(e.response.data.message)
      }
    },
    cancel() {
      this.selectedPermissions = []
      this.dialog = false
    },
    getPermissionClasses(perm) {
      let classes = ''
      if (this.userRolePermissions.includes(perm) && !this.selectedPermissions.includes(perm)) {
        classes = 'primary'
      }
      if (!this.userRolePermissions.includes(perm) && this.selectedPermissions.includes(perm)) {
        classes = 'primary--text'
      }
      return classes
    }
  }
}
</script>

<style lang="scss">
.perm-section {
  border: 1px solid #ebebeb;
  margin-top: 10px;
  border-radius: 7px;
  margin-bottom: 10px;
  background-color: #f5f5f5;

  .perm-section-title {
    padding: 7px;
    border-bottom: 1px solid #ebebeb;
  }

  .perm-section-content {
    padding: 7px;
    background-color: #fff;
  }
}
</style>
