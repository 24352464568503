var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-data',{attrs:{"title":_vm.title,"table-headers":_vm.tableHeaders,"form-scheme":_vm.formScheme,"resource":_vm.resource,"allow-default-actions":true},on:{"inputChange":_vm.handleChange},scopedSlots:_vm._u([{key:"item.global",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.global,"hide-details":"auto","disabled":""}})]}},{key:"item.twoFactorEnabled",fn:function(ref){
var item = ref.item;
return [(_vm.$can((_vm.resource + ".otp_auth_qr")))?_c('div',[_c('v-icon',{attrs:{"small":"","color":item.twoFactorEnabled === true ? 'primary' : 'grey lighten-2'},on:{"click":function () { return _vm.getOTPAuthQR(item.id); }}},[_vm._v(" "+_vm._s(item.twoFactorEnabled === true ? 'mdi-qrcode' : 'mdi-minus')+" ")])],1):_c('div',[_c('v-icon',{attrs:{"small":"","color":item.twoFactorEnabled === true ? 'primary' : 'grey lighten-2'}},[_vm._v(" "+_vm._s(item.twoFactorEnabled === true ? 'mdi-check' : 'mdi-minus')+" ")])],1)]}},{key:"item.saml",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.saml === true ? 'primary' : 'grey lighten-2'}},[_vm._v(" "+_vm._s(item.saml === true ? 'mdi-google' : 'mdi-minus')+" ")])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.role)+" ")])]}},{key:"action-buttons",fn:function(ref){
var item = ref.item;
return [(item.role !== _vm.$store.getters['usersPermissions/mainRole'])?_c('table-btn',{attrs:{"item":item,"tooltip-text":"Edit user permissions","icon":"folder-account-outline","can":"users.management.permissions","fn":function () { return _vm.userPermissions(item); },"color":item.permissions !== undefined && item.permissions.length > 0 ? 'primary' : 'grey'}}):_vm._e()]}}],null,true)})],1)],1),_c('user-permissions-dialog',{ref:"userPermissionsDialog"}),_c('dialog-image',{ref:"imageZoom"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }