<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          :title="title"
          :table-headers="tableHeaders"
          :form-scheme="formScheme"
          :resource="resource"
          :allow-default-actions="true"
          @inputChange="handleChange"
        >
          <template #[`item.global`]="{ item }">
            <v-switch
              :input-value="item.global"
              hide-details="auto"
              disabled
            />
          </template>

          <template #[`item.twoFactorEnabled`]="{ item }">
            <div v-if="$can(`${resource}.otp_auth_qr`)">
              <v-icon
                small
                :color="item.twoFactorEnabled === true ? 'primary' : 'grey lighten-2'"
                @click="() => getOTPAuthQR(item.id)"
              >
                {{ item.twoFactorEnabled === true ? 'mdi-qrcode' : 'mdi-minus' }}
              </v-icon>
            </div>
            <div v-else>
              <v-icon
                small
                :color="item.twoFactorEnabled === true ? 'primary' : 'grey lighten-2'"
              >
                {{ item.twoFactorEnabled === true ? 'mdi-check' : 'mdi-minus' }}
              </v-icon>
            </div>
          </template>

          <template #[`item.saml`]="{ item }">
            <v-icon
              small
              :color="item.saml === true ? 'primary' : 'grey lighten-2'"
            >
              {{ item.saml === true ? 'mdi-google' : 'mdi-minus' }}
            </v-icon>
          </template>

          <template #[`item.role`]="{ item }">
            <v-chip
              class="ma-2"
              small
            >
              {{ item.role }}
            </v-chip>
          </template>

          <!--template #[`item.user_tags`]="{ item }">
            <v-chip
              v-for="(tag, key) of item.user_tags"
              :key="key"
              :class="`ma-2 ${tag.color === 'white' ? 'black--text' : 'white--text'} `"
              small
              :color="tag.color"
              :style="`border: 1px solid #ebebeb !important;`"
            >
              {{ tag.name }}
            </v-chip>
          </template-->

          <template #action-buttons="{ item }">
            <table-btn
              v-if="item.role !== $store.getters['usersPermissions/mainRole']"
              :item="item"
              :tooltip-text="`Edit user permissions`"
              :icon="`folder-account-outline`"
              :can="`users.management.permissions`"
              :fn="() => userPermissions(item)"
              :color="item.permissions !== undefined && item.permissions.length > 0 ? 'primary' : 'grey'"
            />
          </template>
        </table-data>
      </v-col>
    </v-row>
    <user-permissions-dialog ref="userPermissionsDialog" />
    <dialog-image ref="imageZoom" />
  </v-container>
</template>

<script>
import Vue from 'vue'
import UserPermissionsDialog from './dialogs/UserPermissions'

export default {
  name: 'Users',
  components: {
    UserPermissionsDialog
  },
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('users.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'users.management',
    syncLoading: false,
    editedItem: {}
  }),
  watch: {
    async 'editedItem.global' (val) {
      Vue.set(this.formScheme.affiliate_team_id, 'disabled', val)
    }
  },
  async mounted() {
    this.title = this.$t('users.title')
    this.tableHeaders = [
      {
        text: this.$t('users.table.name'),
        align: 'start',
        value: 'name'
      },
      { text: this.$t('users.table.email'), value: 'email' },
      // { text: this.$t('users.table.team'), value: 'team' },
      { text: this.$t('users.table.user_tags'), value: 'user_tags' },
      { text: this.$t('users.table.role'), value: 'role', align: 'center', width: '100px' },
      { text: this.$t('users.table.global'), value: 'global', align: 'center', width: '90px' },
      { text: this.$t('users.table.2fa'), value: 'twoFactorEnabled', align: 'center', width: '90px' },
      { text: this.$t('users.table.saml'), value: 'saml', align: 'center', width: '90px' }
    ]

    this.formScheme = {
      name: {
        type: 'text',
        label: this.$t('users.tableForm.name'),
        createRules: 'req',
        updateRules: []
      },
      email: {
        type: 'text',
        label: this.$t('users.tableForm.email'),
        createRules: 'req',
        updateRules: []
      },
      password: {
        type: 'password',
        label: this.$t('users.tableForm.password'),
        ref: 'password',
        data: {
          show: false
        },
        createRules: 'req',
        updateRules: []
      },
      passwordConfirm: {
        type: 'password',
        label: this.$t('users.tableForm.passwordConfirm'),
        ref: 'passwordConfirm',
        data: {
          show: false
        },
        createRules: [
          v => !!v || this.$t('users.tableForm.validation.fieldIsRequired'),
          // v => `compareStrings:password,passwordConfirm|${this.$t('users.tableForm.validation.fieldIsRequired')}`
          v => v === this.editedItem.password || this.$t('users.tableForm.validation.passwordsMustMatches')
        ],
        updateRules: [
          this.editedItem.password !== ''
            ? v => v === this.editedItem.password || this.$t('users.tableForm.validation.passwordsMustMatches')
            : []
        ],
        virtual: true
      },
      role: {
        type: 'select',
        label: this.$t('users.tableForm.role'),
        items: [
          { text: 'User', value: 'user' },
          { text: 'Support', value: 'support' },
          { text: 'Supervisor', value: 'supervisor' }
          // { text: 'Root', value: 'root' }
        ],
        createRules: 'req',
        updateRules: []
      }
      // affiliate_team_id: {
      //   type: 'autocomplete',
      //   label: this.$t('domains.tableForm.affiliate'),
      //   items: await this.$store.dispatch('affiliatesTeams/all'),
      //   createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
      //   updateRules: []
      // },
      // global: {
      //   type: 'switch',
      //   label: this.$t('users.tableForm.globalUser'),
      //   createRules: [],
      //   updateRules: []
      // }
    }

    if (this.$currentUser.role === process.env.VUE_APP_MAIN_ROLE || this.$can('users.management.set_global') === true) {
      this.formScheme = this.$helper.addToObject(this.formScheme, 'global', {
        type: 'switch',
        label: this.$t('users.tableForm.globalUser'),
        createRules: [],
        updateRules: []
      }, 'affiliate_team_id')
    }

    if (this.$currentUser.role === process.env.VUE_APP_MAIN_ROLE || this.$currentUser.global === true) {
      this.tableHeaders = this.$helper.addToArray(this.tableHeaders, {
        text: this.$t('users.table.team'),
        value: 'team'
      }, 1)
      this.formScheme = this.$helper.addToObject(this.formScheme, 'affiliate_team_id', {
        type: 'autocomplete',
        label: this.$t('users.tableForm.team'),
        items: await this.$store.dispatch('affiliatesTeams/all'),
        createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        updateRules: [],
        clearable: true,
        md: 12
      }, 'user_tags')
    }
  },
  methods: {
    handleChange(event) {
      this.editedItem = event
    },
    async userPermissions(user) {
      await this.$refs.userPermissionsDialog.open(user, {})
    },
    async getOTPAuthQR(id) {
      const response = await this.$store.dispatch('users/getOTPAuthQR', id)
      await this.$refs.imageZoom.open({ url: response.qr }, { width: '196', closeBtn: false })
    }
  }
}
</script>

<style lang="scss">
.v-input--selection-controls {
  margin-top: 0;
}
</style>
